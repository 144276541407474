import update from 'immutability-helper';
import {
  GET_INSURANCE_CONFIG_ERROR,
  GET_INSURANCE_CONFIG_PENDING,
  GET_INSURANCE_CONFIG_SUCCESS,
} from '../actions/actionType';

const initialState = {
  loading: false,
  data: [],
  pagination: {},
  error: '',
};

const insuranceConfigReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case GET_INSURANCE_CONFIG_PENDING:
      return update(state, {
        loading: { $set: true },
        error: { $set: '' },
      });

    case GET_INSURANCE_CONFIG_SUCCESS:
      return update(state, {
        loading: { $set: false },
        data: { $set: actions.payload.data },
        pagination: { $set: actions.payload.paginator },
        error: { $set: '' },
      });

    case GET_INSURANCE_CONFIG_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: actions.payload },
      });

    default:
      return state;
  }
};

export default insuranceConfigReducer;
