import {
  SET_BANK_STATEMENT,
  SET_SETTLEMENT_DATA,
  SET_SETTLEMENT_RECONSILE,
  SET_THIRD_PARTY,
} from '../actions/actionType';
import update from 'immutability-helper';

const initialState = {
  third_party: '',
  id: '',
  is_match: '',
  enabled: {
    settlement: false,
    bank: false,
  },
  settlement_data: [],
  bank_statement: [],
  date: '',
};

function settlementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THIRD_PARTY:
      return update(state, {
        third_party: {
          $set: action.payload,
        },
      });
    case SET_SETTLEMENT_DATA:
      return update(state, {
        settlement_data: {
          $set: action.payload,
        },
      });
    case SET_BANK_STATEMENT:
      return update(state, {
        bank_statement: {
          $set: action.payload,
        },
      });

    case SET_SETTLEMENT_RECONSILE:
      return update(state, {
        id: {
          $set: action.payload.id,
        },
        is_match: {
          $set: action.payload.is_match,
        },
        enabled: {
          settlement: {
            $set: action.payload?.enabled?.settlement || state.enabled.settlement,
          },
          bank: {
            $set: action.payload?.enabled?.bank || state.enabled.bank,
          },
        },
        date: {
          $set: action.payload?.date || state.date,
        },
      });
    default:
      return state;
  }
}

export default settlementReducer;
