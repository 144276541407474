import React from 'react';
// import { getSessionStorage } from '../../utils/cookie';
import { useDispatch } from 'react-redux';
import { validateEmail } from '../../redux/actions/auth';
import { useHistory } from 'react-router-dom';

const EmailValidate = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    const initValidate = async () => {
      const getUrlParams = new URLSearchParams(window.location.search);
      if (!getUrlParams.toString()) history.replace('/login');
      const validate = await validateEmail({ EmailKey: getUrlParams.get('key') });

      if (validate) {
        const redirect = new URLSearchParams();
        redirect.set('email', validate?.data?.data?.Email);
        redirect.set('key', validate?.data?.data?.ForgotPasswordKey);
        history.replace('/reset-password/validate?' + redirect.toString());
      } else {
        history.replace('/login');
      }
    };

    initValidate();
  }, [dispatch, history]);

  return <></>;
};

export default EmailValidate;
