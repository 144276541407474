// auth
export const LOGIN = 'LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

// toolbar
export const SET_TOOLBAR = 'SET_TOOLBAR';

// merchant
export const GET_ALL_MERCHANT_BIZ = 'GET_ALL_MERCHANT_BIZ';
export const GET_MERCHANT_DETAIL = 'GET_MERCHANT_DETAIL';
export const GET_METODE_PEMBAYARAN = 'GET_METODE_PEMBAYARAN';
export const UPDATE_MERCHANT_DETAIL_STATUS = 'UPDATE_MERCHANT_DETAIL_STATUS';
export const GET_BIZ_TYPE = 'GET_BIZ_TYPE';
export const GET_BIZ_CATEGORY = 'GET_BIZ_CATEGORY';

// charge system
export const GET_ALL_CHARGE_SYSTEM = 'GET_ALL_CHARGE_SYSTEM';
export const GET_ALL_CHARGE_SYSTEM_TP = 'GET_ALL_CHARGE_SYSTEM_TP';

// User Management
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const ADD_NEW_USER = 'ADD_NEW_USER';

// Withdrawal
export const GET_ALL_WITHDRAWAL = 'GET_ALL_WITHDRAWAL';
export const GET_WITHDRAWAL_DETAIL = 'GET_WITHDRAWAL_DETAIL';
export const UPDATE_WITHDRAWAL_STATUS = 'UPDATE_WITHDRAWAL_STATUS';

// Reports
export const GET_REPORT_TRANSACTION_SUCCESS = 'GET_REPORT_TRANSACTION_SUCCESS';
export const GET_REPORT_SETTLEMENT_SUCCESS = 'GET_REPORT_SETTLEMENT_SUCCESS';
export const GET_REPORT_WITHDRAWAL_SUCCESS = 'GET_REPORT_WITHDRAWAL_SUCCESS';
export const GET_REPORT_DONATION_SUCCESS = 'GET_REPORT_DONATION_SUCCESS';
export const GET_REPORT_DONATION_TRANSFER_SUCCESS = 'GET_REPORT_DONATION_TRANSFER_SUCCESS';
export const GET_REPORT_INSURANCE_SUCCESS = 'GET_REPORT_INSURANCE_SUCCESS';
export const GET_REPORT_INSURANCE_TRANSFER_SUCCESS = 'GET_REPORT_INSURANCE_TRANSFER_SUCCESS';
export const GET_REPORT_JOURNAL_SUCCESS = 'GET_REPORT_JOURNAL_SUCCESS';
export const GET_REPORT_JOURNAL_SUMMARY_SUCCESS = 'GET_REPORT_JOURNAL_SUMMARY_SUCCESS';
export const GET_REPORT_PENDING = 'GET_REPORT_PENDING';
export const GET_REPORT_PENDING_CHILD = 'GET_REPORT_PENDING_CHILD';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

// Insurance Config

export const GET_INSURANCE_CONFIG_PENDING = 'GET_INSURANCE_CONFIG_PENDING';
export const GET_INSURANCE_CONFIG_ERROR = 'GET_INSURANCE_CONFIG_ERROR';
export const GET_INSURANCE_CONFIG_SUCCESS = 'GET_INSURANCE_CONFIG_ERROR';

// Refund Status Transaction
export const GET_REFUND_STATUS = 'GET_REFUND_STATUS';
export const GET_REFUND_STATUS_DETAIL = 'GET_REFUND_STATUS_DETAIL';
export const GET_REFUND_REPORT = 'GET_REFUND_REPORT';
export const SET_THIRD_PARTY = 'SET_THIRD_PARTY';
export const SET_SETTLEMENT_DATA = 'SET_SETTLEMENT_DATA';
export const SET_BANK_STATEMENT = 'SET_BANK_STATEMENT';
export const SET_SETTLEMENT_RECONSILE = 'SET_SETTLEMENT_RECONSILE';
