import { SET_TOOLBAR } from '../actions/actionType';

const initialState = {
  toolbar: '',
};

function toolbarReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOOLBAR:
      return {
        toolbar: action.payload,
      };
    default:
      return state;
  }
}

export default toolbarReducer;
