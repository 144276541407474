import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import toolbarReducer from './toolbarReducer';
import merchantReducer from './merchantReducer';
import chargeSystemReducer from './chargeSystemReducer';
import userManagementReducer from './userManagementReducer';
import withdrawalReducer from './withdrawalReducer';
import { USER_LOGOUT } from '../actions/actionType';
import reportReducer from './reportReducer';
import insuranceConfigReducer from './insuranceConfigReducer';
import refundStatusReducer from './refundStatusReducer';
import settlementReducer from './settlementReducer';

const appReducer = combineReducers({
  profile: profileReducer,
  toolbar: toolbarReducer,
  merchant: merchantReducer,
  chargeSystem: chargeSystemReducer,
  userManagement: userManagementReducer,
  withdrawal: withdrawalReducer,
  report: reportReducer,
  insuranceConfig: insuranceConfigReducer,
  refundStatus: refundStatusReducer,
  settlement: settlementReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
