import update from 'immutability-helper';
import { LOGIN } from '../actions/actionType';

const initialState = {
  profile: {},
};

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return update(state, {
        profile: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}

export default profileReducer;
