import update from 'immutability-helper';
import {
  GET_ALL_MERCHANT_BIZ,
  GET_MERCHANT_DETAIL,
  UPDATE_MERCHANT_DETAIL_STATUS,
  GET_METODE_PEMBAYARAN,
  GET_BIZ_TYPE,
  GET_BIZ_CATEGORY,
} from '../actions/actionType';

const initialState = {
  merchants: [],
  paginator: {},
  merchantDetail: {},
  paymentMethod: [],
  bizType: [],
  bizCategory: [],
};

function merchantReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MERCHANT_BIZ:
      return update(state, {
        merchants: {
          $set: action.payload,
        },
        paginator: { $set: action.paginator },
      });
    case GET_MERCHANT_DETAIL:
      return update(state, {
        merchantDetail: {
          $set: action.payload,
        },
      });
    case UPDATE_MERCHANT_DETAIL_STATUS:
      return update(state, {
        merchantDetail: {
          status: {
            $set: action.payload.status,
          },
        },
      });
    case GET_METODE_PEMBAYARAN:
      return update(state, {
        paymentMethod: {
          $set: action.payload,
        },
      });
    case GET_BIZ_TYPE:
      return update(state, {
        bizType: { $set: action.payload },
      });
    case GET_BIZ_CATEGORY:
      return update(state, {
        bizCategory: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default merchantReducer;
