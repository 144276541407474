import update from 'immutability-helper';
import { GET_ALL_CHARGE_SYSTEM, GET_ALL_CHARGE_SYSTEM_TP } from '../actions/actionType';

const initialState = {
  chargeSystem: [],
  paginator: {},
  chargeSystemTp: [],
};

export default function chargeSystemReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CHARGE_SYSTEM:
      return update(state, {
        chargeSystem: {
          $set: action.payload.data,
        },
        paginator: {
          $set: action.payload.paginator,
        },
      });
    case GET_ALL_CHARGE_SYSTEM_TP:
      return update(state, {
        chargeSystemTp: {
          $set: action.payload.data,
        },
        paginator: {
          $set: action.payload.paginator,
        },
      });
    default:
      return state;
  }
}
