import update from 'immutability-helper';
import { GET_REFUND_STATUS, GET_REFUND_STATUS_DETAIL, GET_REFUND_REPORT } from '../actions/actionType';

const initialState = {
  refunds: [],
  refundDetail: {},
  reportRefunds: [],
};

function refundStatusReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REFUND_STATUS:
      return update(state, {
        refunds: {
          $set: action.payload,
        },
      });

    case GET_REFUND_STATUS_DETAIL:
      return update(state, {
        refundDetail: {
          $set: action.payload,
        },
      });

    case GET_REFUND_REPORT:
      return update(state, {
        reportRefunds: { $set: action.payload },
        error: { $set: '' },
      });

    default:
      return state;
  }
}

export default refundStatusReducer;
