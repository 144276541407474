import update from 'immutability-helper';
import { ADD_NEW_USER, GET_ALL_USERS } from '../actions/actionType';

const initialState = {
  users: [],
};

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return update(state, {
        users: {
          $set: action.payload.data,
        },
        pagination: {
          $set: action.payload.paginator,
        },
      });
    case ADD_NEW_USER:
      return update(state, {
        users: { $push: [action.payload] },
      });
    default:
      return state;
  }
}
