import update from 'immutability-helper';
import { GET_ALL_WITHDRAWAL, GET_WITHDRAWAL_DETAIL } from '../actions/actionType';

const initialState = {
  withdrawals: [],
  paginator: {},
  withdrawalDetail: {},
};

function withdrawalReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_WITHDRAWAL:
      return update(state, {
        withdrawals: {
          $set: action.payload.data,
        },
        paginator: {
          $set: action.payload.paginator,
        },
      });
    case GET_WITHDRAWAL_DETAIL:
      return update(state, {
        withdrawalDetail: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default withdrawalReducer;
