import { setCookie, removeSessionStorage, getSessionStorage, setSessionStorage } from '../../utils/cookie';
import { apiPost, apiPostCustomAlert } from '../../utils/apiFunction';
import { LOGIN, USER_LOGOUT } from './actionType';

export const login = (body, { history, setError }) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_URL}client-manager-login`;
  const response = await apiPostCustomAlert({ url, body, alert: setError });
  if (response?.data?.status === 'success') {
    dispatch({ type: LOGIN, payload: response.data.data });
    setSessionStorage('token-web-admin', response?.data?.data?.Token);
    setSessionStorage('refresh-token-web-admin', response?.data?.data?.RefreshToken);
    history.push('/');
  }
};

export const forgot = async (body, { setError }) => {
  const url = `${process.env.REACT_APP_BASE_URL}client-manager-password-forgot-initial`;
  const response = await apiPostCustomAlert({ url, body, alert: setError });
  if (response?.data?.status === 'success') {
    return true;
  }

  return false;
};

export const reset = async (body, { setError }) => {
  const url = `${process.env.REACT_APP_BASE_URL}client-manager-password-validate`;
  const response = await apiPostCustomAlert({ url, body, alert: setError });
  if (response?.data?.status === 'success') {
    return true;
  }

  return false;
};

export const validateEmail = async (body) => {
  const url = `${process.env.REACT_APP_BASE_URL}client-manager-email-validate`;
  const response = await apiPost(url, body);

  if (response?.data?.status === 'success') {
    return response;
  }

  return false;
};

export const refreshToken = async () => {
  const url = `${process.env.REACT_APP_BASE_URL}client-manager-refresh-token`;
  const RefreshToken = getSessionStorage('refresh-token-web-admin');
  const response = await apiPost(url, { RefreshToken });

  if (response?.data?.status === 'success') {
    setSessionStorage('token-web-admin', response?.data?.data?.Token);
    setSessionStorage('refresh-token-web-admin', response?.data?.data?.RefreshToken);

    return;
  }

  // setCookie('token-web-admin', '');
  // setCookie('refresh-token-web-admin', '');
  // window.location.href = '/login';
};

export const logout = () => async (dispatch) => {
  removeSessionStorage('token-web-admin');
  removeSessionStorage('refresh-token-web-admin');
  await dispatch({
    type: USER_LOGOUT,
  });
};

// export const generateXSRFToken = async () => {
//   const url = `${process.env.REACT_APP_BASE_URL}v2/tok`;
//   const response = await apiPost(url, null, true);

//   setCookie('xsrf-token', response?.data?.data?._tok);

//   /* Untuk pengetesan secara local, ikuti langkah berikut:
//       1. Call api generate token csrf di postman
//       2. copy token tersebut dan simpan di cookie dengan key xsrf-token
//       */
//   // setCookie('xsrf-token', 'e9311b85-cdbc-4830-ba8b-cb40dbcff09e');
// };
